<template>
  <router-link tag="a" :to="{ name: 'media-owners.single.screens', params: { mediaOwnerId: mediaOwner.id } }">
    <div class="row">
      <div class="col-12 col-sm-8 col-xl-9">
        <div class="row">
          <div class="col d-flex align-items-center">
            <p class="strong text-truncate">{{ mediaOwner.name }}</p>
          </div>
        </div><!-- /.row -->
      </div>
      <div class="col-12 col-sm-4 col-xl-3">
        <span class="status status-success" v-if="mediaOwner.status === 'enabled'">Enabled</span>
        <span class="status status-danger" v-if="mediaOwner.status === 'disabled'">Disabled</span>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    mediaOwner: {
      required: true,
      type: Object
    }
  }
}
</script>
