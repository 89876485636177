<template>
  <fragment>
    <app-header :title="mediaOwner.name"/>
    <slot name="nav"/>

    <div class="container container-max-sm">
      <media-owners-details-form>
        <template #save>
          <div class="row">
            <div class="col">
              <c-button v-if="mediaOwner.status !== 'disabled'" @click="handleDisable" variant="outline-danger">Disable</c-button>
              <c-button v-else @click="handleEnable" variant="success">Enable</c-button>
            </div>
            <div class="col-auto">
              <c-button @click="handleSave" variant="primary" validate>
                Save
              </c-button>
            </div>
          </div>
        </template>
      </media-owners-details-form>
    </div>
  </fragment>
</template>

<script>
import { get, call } from 'vuex-pathify'
import MediaOwnersDetailsForm from '@/components/media-owners/DetailsForm'
import { toast } from '@chameleon/chameleon-vue'
import { pick } from 'lodash'

export default {
  components: {
    MediaOwnersDetailsForm
  },

  computed: {
    ...get('mediaOwners', ['mediaOwner'])
  },

  methods: {
    ...call('mediaOwners', ['updateMediaOwner']),

    async handleSave () {
      const payload = pick(this.mediaOwner, [
        'name',
        'tags'
      ])

      await this.updateMediaOwner({ id: this.mediaOwner.id, payload: payload })
    },

    async handleDisable () {
      try {
        await this.updateMediaOwner({ id: this.mediaOwner.id, payload: { status: 'disabled' } })
        toast('Media owner disabled', 'success')
      } catch (error) {
        if (error.response && error.response.status === 400) {
          toast(error.response.data.message, 'danger')
        } else {
          throw error
        }
      }
    },

    async handleEnable () {
      await this.updateMediaOwner({ id: this.mediaOwner.id, payload: { status: 'enabled' } })
      toast('Media owner enabled', 'success')
    }
  }
}
</script>
