<template>
  <fragment>
    <app-header title="Media Owners">
      <template #actions>
        <router-link
          class="btn btn-primary mt-2 mt-xl-0"
          :to="{ name: 'media-owners.add' }"
        >
          <i class="far fa-plus"></i> Media Owner
        </router-link>
      </template>
    </app-header>

    <div class="container container-fluid mt-4 mb-4">
      <media-owners-filters v-model="params" />

      <c-data-list :items="mediaOwners" @load="load">
        <template #columns>
          <div class="col-sm-8 col-xl-9">Name</div>
          <div class="col-sm-4 col-xl-3">Status</div>
        </template>

        <template #default="{ item }">
          <media-owners-list-item :mediaOwner="item" />
        </template>
      </c-data-list>
    </div>
  </fragment>
</template>

<script>
import { helperComputed, helperMethods } from '@/vuex/modules/media-owners'
import { compareQueryParams } from '@codium/codium-vue'
import MediaOwnersListItem from './MediaOwnersListItem'
import MediaOwnersFilters from './MediaOwnersFilters'

export default {
  components: {
    MediaOwnersListItem,
    MediaOwnersFilters
  },

  data () {
    return {
      params: null
    }
  },

  watch: {
    params: compareQueryParams(async function (params) {
      await this.getMediaOwners({ params, fresh: true })
    })
  },

  computed: {
    ...helperComputed
  },

  methods: {
    ...helperMethods,

    async load (done) {
      const { params } = this

      try {
        await this.getMediaOwners({ params })

        done()
      } catch {
        done(true)
      }
    }
  }
}
</script>
