<template>
  <c-input
    as="multiselect"
    class="multiselect-multiple"
    multiple
    v-model="selectedTags"
    :placeholder="holder"
    :close-on-select="false"
    :preselect-first="false"
    :show-labels="false"
    :options="screenTags"
    label="name"
    track-by="id"
  />
</template>

<script>
import { get, call } from 'vuex-pathify'

export default {
  props: {
    value: Array,
    holder: {
      default: 'Select'
    }
  },

  data () {
    return {
      tagOptions: []
    }
  },

  computed: {
    ...get('lists/screenTags', ['screenTags']),

    selectedTags: {
      get () {
        const ids = this.value.map(({ id }) => id)

        return this.screenTags.filter(({ id }) => {
          return ids.includes(id)
        })
      },

      set (options) {
        this.$emit('input', options)
      }
    }
  },

  methods: {
    getScreenTags: call('lists/screenTags/getScreenTags')
  },

  async created () {
    this.getScreenTags()
  }
}
</script>
