<template>
  <div>
    <app-header title="Add Media Owner" />

    <validation-observer tag="form" class="container container-max-sm" @submit.native.prevent ref="validation">
      <form class="card" @submit.prevent>
        <div class="card-body">
          <h2 class="card-title">Media Owner Details</h2>

          <validation-provider
            tag="div"
            class="mb-3"
            name="name"
            rules="required"
          >
            <label>Name</label>
            <c-input type="text" v-model="stateName" />
            <c-help-block />
          </validation-provider>

          <div class="text-end">
            <c-button
              type="submit"
              variant="primary"
              @click="submit"
              validate
            >
              Add Media Owner
            </c-button>
          </div>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import { stateMapper, helperMethods, helperComputed } from '@/vuex/modules/media-owners'
import { toast } from '@chameleon/chameleon-vue'

export default {
  mixins: [stateMapper],

  computed: helperComputed,

  created () {
    this.resetMediaOwner()
  },

  beforeDestroy () {
    this.resetMediaOwner()
  },

  methods: {
    ...helperMethods,

    async submit () {
      try {
        await this.storeMediaOwner(this.mediaOwner)

        this.$router.push({ name: 'media-owners' })
      } catch (error) {
        if (error.response && error.response.status !== 422) {
          toast('Something went wrong trying to create media owner', 'danger')
          return
        }

        throw error
      }
    }
  }
}
</script>
