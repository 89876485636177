<template>
  <router-view v-if="mediaOwner.id">
    <template #nav>
      <c-horizontal-nav>
        <c-horizontal-nav-link :to="{ name: 'media-owners.single.screens' }">Screens</c-horizontal-nav-link>
        <c-horizontal-nav-link :to="{ name: 'media-owners.single.details' }">Details</c-horizontal-nav-link>
      </c-horizontal-nav>
    </template>
  </router-view>
</template>

<script>
import { get, call } from 'vuex-pathify'

export default {
  props: {
    mediaOwnerId: Number
  },

  computed: {
    ...get('mediaOwners', ['mediaOwner'])
  },

  beforeDestroy () {
    this.resetMediaOwner()
  },

  watch: {
    mediaOwnerId: {
      async handler () {
        await this.getMediaOwner(this.mediaOwnerId)
      },
      immediate: true
    }
  },

  methods: {
    ...call('mediaOwners', ['getMediaOwner', 'resetMediaOwner'])
  }
}
</script>
