<template>
    <form class="card" @submit.prevent>
      <validation-observer tag="div" class="card-body card-body-lg" @submit.native.prevent ref="validation">
        <h2 class="card-title">Media Owner Details</h2>

        <validation-provider
          tag="div"
          class="mb-3"
          name="name"
          rules="required"
        >
          <label>Name</label>
          <c-input type="text" v-model="stateName" />
          <c-help-block />
        </validation-provider>

        <validation-provider
          tag="div"
          class="mb-3"
          name="tags"
        >
          <label>Tags</label>
          <media-owner-tag-select v-model="stateTags" />
          <c-help-block />
        </validation-provider>

        <div class="row mb-3">
          <div class="col">
            <span class="form-label">Status</span>
          </div>
          <div class="col-auto">
            <span class="status" :class="statusClass">{{ mediaOwner.status }}</span>
          </div>
        </div>

        <slot name="save"/>
      </validation-observer>
    </form>
</template>

<script>
import { stateMapper } from '@/vuex/modules/media-owners'
import MediaOwnerTagSelect from '@/components/forms/MediaOwnerTagSelect'
import { get } from 'vuex-pathify'

export default {
  mixins: [stateMapper],

  components: {
    MediaOwnerTagSelect
  },

  computed: {
    ...get('mediaOwners', ['mediaOwner']),

    statusClass () {
      if (this.mediaOwner.status === 'enabled') {
        return 'status-success'
      } else if (this.mediaOwner.status === 'disabled') {
        return 'status-danger'
      } else {
        return 'status-info'
      }
    },
  }
}
</script>
