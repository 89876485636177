export const downloadBlob = (...args) => {
  let data
  let filename

  if (args.length === 2) {
    [data, filename] = args
  } else {
    const [response] = args

    if (!response.headers['content-disposition']) {
      throw new Error('Cannot download blob when no Content-Disposition header present. Ensure CORs is set to expose this header.')
    }

    const filenameMatches = response.headers['content-disposition'].match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)

    data = response.data
    filename = filenameMatches[1].replaceAll('"', '')
  }

  const anchor = document.createElement('a')
  anchor.href = window.URL.createObjectURL(new Blob([data]))
  anchor.setAttribute('download', filename)

  document.body.appendChild(anchor)

  anchor.click()
  anchor.remove()
}
