<template>
  <c-data-filters>
    <template #basic>
      <label class="sr-only">Search</label>
      <c-input type="text" :value="filters.search" @input="updateSearch">
        <i slot="prepend" class="fal fa-search" />
      </c-input>
    </template>

    <div class="row">
      <div class="col-lg-8 col-xl-9 mb-3">
        <div class="mb-3">
          <label>Search</label>

          <c-input type="text" :value="filters.search" @input="updateSearch">
            <i slot="prepend" class="fal fa-search" />
          </c-input>
        </div>
      </div>
      <div class="col-lg-4 col-xl-3 mb-3">
        <div class="mb-3">
          <label>Account Status</label>

          <c-input
            as="multiselect"
            placeholder
            :options="statusOptions"
            :preselect-first="true"
            :show-labels="false"
            :allow-empty="false"
            label="name"
            v-model="status"
          >
            <i slot="prepend" class="fal fa-shield" />
          </c-input>
        </div>
      </div>
      <div class="col-12 mb-3">
          <label class="form-label">Tag</label>
          <media-owner-tag-select v-model="selectedTags" @input="updateTags"/>
      </div>
    </div>
  </c-data-filters>
</template>

<script>
import { useQueryParamFilters } from '@codium/codium-vue'
import { debounce } from 'lodash'
import MediaOwnerTagSelect from '@/components/forms/MediaOwnerTagSelect'

export default {
  mixins: [useQueryParamFilters()],

  components: {
    MediaOwnerTagSelect
  },

  data () {
    return {
      filters: {
        search: null,
        status: 'all',
        tags: []
      },
      statusOptions: [
        { name: 'All', value: 'all' },
        { name: 'Enabled', value: 'enabled' },
        { name: 'Disabled', value: 'disabled' },
      ],
      selectedTags: []
    }
  },

  computed: {
    status: {
      get () {
        return this.statusOptions.find(({ value }) => value === this.filters.status)
      },

      set ({ value }) {
        this.filters.status = value
      }
    },

    sortBy: {
      get () {
        const { filters, sortByOptions } = this

        return sortByOptions.find(({ order, sort }) => filters.sort === sort && filters.order === order)
      },

      set ({ sort, order }) {
        this.filters.sort = sort
        this.filters.order = order
      }
    }
  },

  methods: {
    updateSearch: debounce(function (value) {
      this.filters.search = value
    }, 500),

    updateTags () {
      this.filters.tags = this.selectedTags.map(({ id }) => id)
    }
  }
}
</script>
