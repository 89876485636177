<template>
  <fragment>
    <app-header title="Screens">
    </app-header>

    <slot name="nav"/>

    <div class="container">
      <screens-list-filters v-model="params" />

      <c-data-list :items="screens" @load="load" class="list-data-screens">
        <template #columns>
          <div class="col-xl-3">Name</div>
          <div class="col-xl-3 flex-grow-1">Tags</div>
          <div class="col-xl-2">Current Brightness</div>
          <div v-if="$isA('admin')" class="col-xl-2">Ambient Brightness</div>
        </template>

        <template #default="{ item }">
          <screens-list-item :screen="item" />
        </template>
      </c-data-list>
    </div>
  </fragment>
</template>

<script>
import { get } from 'vuex-pathify'
import { helperComputed, helperMethods } from '@/vuex/modules/screens'
import { compareQueryParams } from '@codium/codium-vue'
import ScreensListItem from './MediaOwnersScreensListItem'
import ScreensListFilters from './MediaOwnersScreensListFilters'
import { http } from '@/utils'
import { downloadBlob } from '@/utils/functions/download-blob'

export default {
  components: {
    ScreensListItem,
    ScreensListFilters
  },

  data () {
    return {
      params: null
    }
  },

  watch: {
    params: compareQueryParams(async function (params) {
      const payload = {
        ...params,
        mediaOwner: this.mediaOwner.id
      }
      await this.getScreens({ params: payload, fresh: true })
    })
  },

  computed: {
    ...helperComputed,
    ...get('mediaOwners', ['mediaOwner'])
  },

  beforeDestroy () {
    this.resetScreens()
  },

  methods: {
    ...helperMethods,

    async load (done) {
      const { params } = this

      try {
        const payload = {
          ...params,
          mediaOwner: this.mediaOwner.id
        }
        await this.getScreens({ params: payload })

        done()
      } catch {
        done(true)
      }
    },

    async downloadZipFile () {
      downloadBlob(await http.post(`${this.mediaOwner.id}/images-download`, {}, { responseType: 'blob' }))
    }
  }
}
</script>
